@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Lateef&display=swap');

:root {
  --primary-color: #006C35;
  --secondary-color: #E4A62F;
  --text-color: #333;
  --background-color: #F4E8D5;
}

body {
  font-family: 'Amiri', serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-content h1 {
  font-family: 'Lateef', cursive;
  font-size: 3.5rem;
  margin: 0;
  animation: fadeIn 1s ease-out;
}

.header-content h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: normal;
  animation: fadeIn 1s ease-out 0.5s both;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

nav ul li a:hover {
  color: var(--secondary-color);
  transform: translateY(-2px);
}

main {
  background-color: white;
  padding: 40px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

section {
  margin-bottom: 60px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

section.visible {
  opacity: 1;
  transform: translateY(0);
}

h2 {
  color: var(--primary-color);
  border-bottom: 2px solid var(--secondary-color);
  padding-bottom: 10px;
}

h3 {
  color: var(--secondary-color);
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.hero-content {
  flex: 1;
  padding-right: 40px;
}

.hero-image {
  flex: 1;
  text-align: center;
}

.hero-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.content-with-image {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 40px;
}

.content-with-image.reverse {
  flex-direction: row-reverse;
}

.text-content {
  flex: 2;
}

.image-container {
  flex: 1;
}

.image-container img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

footer {
  text-align: center;
  margin-top: 20px;
  color: var(--text-color);
  font-size: 0.9rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  header {
    padding: 15px;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content h2 {
    font-size: 1.2rem;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  nav {
    display: none;
  }

  nav.open {
    display: block;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 10px 0;
  }

  main {
    padding: 20px;
  }

  .hero, .content-with-image, .content-with-image.reverse {
    flex-direction: column;
  }

  .hero-content, .text-content {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .hero-image, .image-container {
    margin-top: 20px;
  }
}